/* Variables */
$grey: #ccc;
$black: #000;
$blue-hover: #0056b3;
$blue: #007bff;
$white: #fff;
$voilet: #8a2be2;
$text-grey: #999;
$button-gradient: linear-gradient(to right, #ff7e5f, #feb47b);
$primary-gradient: linear-gradient(to right, #00aaff, #00ff6c);
$light-blue: #d3e9ff;
$fontFamilyRubik: "Rubik", sans-serif;

/* Mixin for Flex Center */
@mixin flex-center {
  display: flex;
  justify-content: center;

  width: 100%;
  height: 100%;
  flex-wrap: wrap;
}
@mixin font-styles {
  font-family: "Rubik", sans-serif;
  font-weight: 400;

  font-size: 14px;
}

.ContackUsSideBar {
  @include flex-center;
  @include font-styles;
}
.requestCall,
.Callusside {
  border-radius: 6px;
  align-items: start;
  @include flex-center;
  @include font-styles;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.requesTcollHeading {
  padding-top: 1.5rem;
}
.collHeading h5,
.requesTcollHeading h5 {
  font-family: $fontFamilyRubik;
}
.Callusside {
  color: $white;
  background-color: #0376f2;
}

.contactCont,
.Callusside {
  padding-top: 2rem;
}
.SubmitBtn {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 0.7rem;
}
.SubmitBtn button {
  @include font-styles;
  font-size: 1rem;
  border-radius: 6px;
  background-color: #faf2ffc4;
  padding: 5px 35px 5px 35px;
  border: transparent;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 0px 0px 1px;
}
.SubmitBtn button {
  background-color: #faf2ff;
}
.inputsBox input::placeholder,
.inputsBox textarea::placeholder {
  font-weight: 400;
  color: $grey;
}

.callBtns {
  height: 100%;
  width: 100%;
  align-items: baseline;
  padding-top: 0.6rem;
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
}
.callBtns a,
.callBtns p {
  text-decoration: none;
  color: $white;
  font-weight: 300;
  border: none;
  background-color: transparent;
  font-size: 16px;
}
.callBtns img {
  margin-right: 9px;
  height: 18px;
  width: 18px;
}
.collingSideBar {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
.SocialMedia {
  padding-top: 10rem;
  padding-bottom: 2.7rem;
}
.SocialMedia button {
  background-color: transparent;
  border: none;
}
.SocialMedia img {
  height: 23px;
  width: 23px;
}
.cont1 {
  width: 100%;
}
.input-container {
  position: relative;
}
.input-container:before {
  content: "+91";
  position: absolute;
  left: 10px;
  top: 68.5%;
  transform: translateY(-50%);
  font-size: 16px;
  color: black;
}
.input-container input {
  padding-left: 44px;
}

@media (min-width: 10px) and (max-width: 1200px) {
  .cont1,
  .cont2 {
    width: 100%;
    height: 100%;
  }
}
@media (min-width: 10px) and (max-width: 750px) {
  .SocialMedia {
    padding-top: 0.6rem;
  }
  .collingSideBar {
    gap: 6px;
  }
}

.modal-header {
  border-bottom: none !important;
}

@media screen and (max-width: 576px) {
  .direcColkumn {
    flex-direction: column;
    justify-content: center;
  }
}

.contctFormL label {
  margin-bottom: 0;
}

.marginBottom {
  margin-bottom: 2rem;
}
