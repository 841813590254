.pricing_heading_wrapper {
  font-family: "Rubik";
  font-weight: 700;
  margin-top: 1rem;
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  margin-bottom: 1rem;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1rem;
}

.pricing_heading_wrapper h3 {
  font-size: 28px;
  font-weight: 700;
}

.pricing_range_buttons {
  display: flex;
  flex-direction: row;
  gap: 0.3rem;
  color: grey;
}

.pricing_range_buttons button {
  padding: 0.4rem 1rem;
  color: var(--text-grey);
  background-color: transparent;
  border: 1px solid var(--text-grey);
}

.pricing_range_buttons button:nth-child(1) {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}

.pricing_range_buttons button:nth-child(3) {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

/* .pricing_range_buttons button:hover {
  color: var(--white);
  border: none;
  background-color: #0376f2;
} */

.benefits {
  height: auto;
}

.card_body {
  padding: 1rem 2rem;
  color: var(--text-light-black);
  cursor: auto;
  border-radius: 7px;
  background-color: #f1f8ff;
  height: 100%;
}

.card_body div {
  display: flex;
}

.card_body div button {
  margin-top: 1rem;
  margin-bottom: 1rem;
  border: 1px solid #0376f2;
  padding: 0.5rem 2rem;
  font-family: "Rubik";
  background-color: var(--white);
  color: #0376f2;
  font-family: "Rubik";
  border-radius: 50px 50px 50px 50px;
}

.card_body div button:hover {
  background-color: #0376f2;
  color: white;
  font-family: "Rubik";
}

.custom_list {
  padding-left: 0;
  margin-top: 0.5rem;
}

.custom_list li {
  font-size: 14px;
  width: 100%;
  font-weight: 500;
  font-family: "Rubik";
  margin-top: 1rem;
  text-align: left;
  cursor: pointer;
}

.benefits ul {
  list-style-type: disc !important;
}

.benefits li {
  top: 0;
  list-style-type: disc;
}

.select_planButton {
  margin-left: auto;
  margin-right: auto;
}

.card {
  border: none;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
    rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
}

.card_body span p {
  background: -webkit-linear-gradient(90deg, #001aff 0%, #b63eff 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin: 0rem 0rem !important;
}

@media (max-width: 767px) {
  .pricing_heading_wrapper {
    display: flex;
    text-align: center;
  }

  .benefits {
    height: fit-content;
  }

  .card_body div button {
    width: 100%;
  }
}

.pricing_faq_subheading h6 {
  font-family: "Rubik";
  color: var(--text-grey);
}

.textSection_pricingPage {
  margin-top: 1rem;
}

.brand_success_section_pricing {
  background-color: whitesmoke;
}

.pricingPage_text_conatiner p {
  font-family: "Rubik";
  color: var(--text-grey);
  font-size: 16px;
}

.logoideas_section_pricingPage {
  margin-top: 2rem;
}

.howitworks_header_section_pricingPage {
  margin-left: auto;
  margin-right: auto;
}

.howitworks_main_heading_pricingPage {
  margin-bottom: 1rem;
}

/* contact us section -------------- */

.contactUs_section {
  margin-top: 2rem;
}

.contactUs_container {
  padding: 2rem 0rem;
  /* /* display: flex; */
  flex-direction: column;
  text-align: center;
}

.contactUs_container h3 {
  font-family: "Rubik";
  font-size: 36px;
  font-weight: 600;
}

.contactUs_container h6 {
  font-family: "Rubik";
  font-size: 16px;
  color: var(--text-light-black);
  font-weight: 400;
}

.contactUs_container button {
  margin-top: 0.5rem;
  padding: 0.5rem 2rem;
  font-family: "Rubik";
  border: none;
  box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s;
}

.contactUs_container button:hover {
  transform: scale(1.1);
}

/* faq section */
.faq_section {
  margin-top: 5rem;
}

.faq_main_heading {
  width: 100%;
  display: flex;
  justify-content: center;
}

.faq_main_heading h3 {
  font-family: "Rubik";
  font-size: 36px;
  font-weight: 600;
}

.faq_subheading h6 {
  font-family: "Rubik";
  font-size: 14px;
  text-align: center;
  font-weight: 400;
}

.faq_headings {
  text-align: center;

  gap: 0.5rem;
}

.dropdown-icon {
  float: right;
  margin-left: 5px;
  transition: transform 0.4s ease;
  cursor: pointer;
}

.active .dropdown-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.accordion-title {
  padding: 1rem;
}

.accordion-item {
  margin-bottom: 1rem;
  border-radius: 8px;
}

.accordion-title-text {
  margin-right: 10px;
}

.accordion_container {
  display: flex;
  align-items: center;
}

.accordion {
  font-family: "Roboto  ", serif;
  width: 100%;
  font-size: 18px;
  font-weight: 400;
}

.accordion-content-text {
  text-align: center;
  font-family: "Rubik" !important;
  font-size: 20px;
  line-height: 1.5;
  color: var(--text-grey);
  /* Add additional font styles as needed */
}

/* Absolute Center Spinner */
.loading {
  position: fixed;
  z-index: 999;
  height: 2em;
  width: 2em;
  overflow: show;
  margin: auto;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
}

/* Transparent Overlay */
.loading:before {
  content: "";
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: radial-gradient(rgba(20, 20, 20, 0.8), rgba(0, 0, 0, 0.8));

  background: -webkit-radial-gradient(
    rgba(20, 20, 20, 0.8),
    rgba(0, 0, 0, 0.8)
  );
}

/* :not(:required) hides these rules from IE9 and below */
.loading:not(:required) {
  /* hide "loading..." text */
  font: 0/0 a;
  color: transparent;
  text-shadow: none;
  background-color: transparent;
  border: 0;
}

.loading:not(:required):after {
  content: "";
  display: block;
  font-size: 10px;
  width: 1em;
  height: 1em;
  margin-top: -0.5em;
  /* background-image: url('../Assets/images/loader.gif');
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 11; */
  -webkit-animation: spinner 150ms infinite linear;
  -moz-animation: spinner 150ms infinite linear;
  -ms-animation: spinner 150ms infinite linear;
  -o-animation: spinner 150ms infinite linear;
  animation: spinner 150ms infinite linear;
  border-radius: 0.5em;
  -webkit-box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
  box-shadow: rgba(255, 255, 255, 0.75) 1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) 1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 1.5em 0 0,
    rgba(255, 255, 255, 0.75) -1.1em 1.1em 0 0,
    rgba(255, 255, 255, 0.75) -1.5em 0 0 0,
    rgba(255, 255, 255, 0.75) -1.1em -1.1em 0 0,
    rgba(255, 255, 255, 0.75) 0 -1.5em 0 0,
    rgba(255, 255, 255, 0.75) 1.1em -1.1em 0 0;
}

/* Animation */

@-webkit-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-moz-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@-o-keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes spinner {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.font14 {
  font-size: 14px;
}
