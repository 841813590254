/* Variables */
$grey: #ccc;
$black: #000;

$blue-hover: #0056b3;
$blue: #007bff;
$white: #fff;
$voilet: #8a2be2;
$text-grey: #999;
$button-gradient: linear-gradient(to right, #ff7e5f, #feb47b);
$primary-gradient: linear-gradient(to right, #00aaff, #00ff6c);
$light-blue: #d3e9ff;
$fontFamilyRubik: "Rubik", sans-serif;

/* Mixin for Flex Center */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.stikyPosuition {
  position: sticky;
  top: 0;
}

/* Inputs Container */
.inputs_container {
  width: 100%;

  input,
  select {
    width: 100%;
    height: 44px;
    border-radius: 6px;
    border: 1px solid $grey;
    padding: 0.5rem 1rem;
    margin-bottom: 0.5rem;
    outline: none;
  }

  select {
    padding: 0.5rem 0;
  }
}
/*All section second Heading color*/
.whatyouget_subheading {
  color: #212121;
}

.whatyouget_subheading h6,
.faq_subheading h6 {
  font-size: 20px !important;
}

/* Hero Section */
.hero {
  height: 100%;
  width: 100%;
  background-color: #fabf28;
}

.HeroContent,
.seconHero {
  align-items: center;

  padding: 2rem;
  padding-right: 0 !important;

  p {
    color: $black;
    font-weight: 500;
    font-family: $fontFamilyRubik;
    font-size: 48px;
  }
}
.seconHero {
  // background: url('../../Assets/Images/heroSectionImg2.png') no-repeat center center/cover;
  background-size: contain !important;
}
.scheduleDemoBTN {
  position: relative;
  padding-top: 2rem;
}

.scheduleDemoBTN button {
  color: $white;
  border-radius: 15px;
  background-color: $blue;
}
.scheduleDemoBTN button:hover {
  color: $white;
  border-radius: 15px;
  background-color: $blue-hover;
}

/* Select Elements */
select {
  color: $text-grey;

  option[disabled] {
    color: $text-grey;
  }

  &::placeholder {
    color: $text-grey;
    font-style: italic;
  }
}

/* Caption Container */
.caption_container {
  margin-top: 2rem;

  p {
    font-size: 14px;
  }

  img {
    width: 120px;
  }
}

/* Hero Bottom Content */
.herobottmcont,
.heroFotter {
  position: relative;
  display: flex;
  flex-wrap: wrap;

  width: 100%;
  padding-left: 10px;
}
.herobottmcont label {
  font-family: $fontFamilyRubik;
  font-weight: 400;
  font-size: 24px;
}
.heroFotter {
  column-gap: 10px;
  padding-top: 10px;
}
.heroFotter label {
  font-family: $fontFamilyRubik;
  font-size: 16px !important;
  font-weight: 600;
}

.heroSectionImg img {
  background-size: contain;
  height: 100%;
  width: 100%;
}

.hero_Left_heading h1 {
  font-family: "DM Serif Display", serif;
  font-weight: 500;
}

.hero_left_subHead {
  font-family: "Lato";
  font-size: 14px;

  span {
    cursor: pointer;
    color: $voilet;
  }
}

.hero_right {
  @include flex-center;
  flex-direction: row;
  gap: 1rem;
}

/* Marquee */
.marquee-container {
  width: 100%;
  overflow: hidden;

  &.speed-1 .marquee {
    animation-duration: 70s;
  }

  &.speed-2 .marquee {
    animation-duration: 100s;
  }

  &.speed-3 .marquee {
    animation-duration: 150s;
  }
}

/* Smart Section */
.smartContainer {
  padding-top: 5rem;
  height: 100%;
  width: 100%;
}
.smartContainer2 {
  padding-top: 2rem;
}
.faq_section_container {
  padding-top: 3rem;
}
.smartcArds {
  padding-top: 2rem;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}
.SmartAndEasy {
  text-align: center;
}

.SmartAndEasy h3 {
  font-size: 44px;
}

.cart1 {
  text-align: center;
  max-width: 230px;
}

@media screen and (max-width: 1300px) {
  .cart1 {
    text-align: center;
    max-width: 300px;
  }
}

@media screen and (max-width: 1050px) {
  .cart1 {
    text-align: center;
    max-width: 260px;
  }
}

.cart1 h6 {
  font-size: 26px;
}

.smartcArds p {
  text-align: center;
  padding-top: 5px;
  font-size: 18px;
  font-family: $fontFamilyRubik;
}
.smartcArds h7 {
  font-size: 14px;
  font-weight: 600;
  font-family: $fontFamilyRubik;
}
.smartcArds img {
  width: 2.8rem;
  height: 3.2rem;
}

.smartEasyHead,
.main-heading {
  flex-direction: column;
  width: 100%;
  font-family: $fontFamilyRubik;
  padding: 2rem 0 0.8rem 0;
}
.main-heading2 {
  @include flex-center;
  align-items: start;
  flex-direction: column;
  width: 100%;
  font-family: $fontFamilyRubik;

  padding: 2rem 0 0.8rem 0;
}

.main-heading2 h3 {
  font-size: 44px;
}

/*--everything section*/
.unique_right_contain {
  @include flex-center;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  padding-left: 5x;
}

.multibrandImg img {
  height: 100%;
  width: 100%;
  // max-height: 300px;
  object-fit: cover;
  border-radius: 10px;
}

.unlimitedSectionImgs {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  gap: 10px;
  padding-top: 2rem;
  font-size: 15px;
  font-weight: 400;
  font-family: $fontFamilyRubik;
}

.unlimitedSectionImgs p {
  margin-left: 7px;
  font-size: 20px;
}

.unlimitedSectionImgs img {
  height: 1.5rem;
  width: 1.5rem;
}

.sellection h3 {
  font-weight: 700;
}
/*-----------How get Section-----------------*/

.about_section {
  @include flex-center;
  padding-top: 5rem;
  padding-bottom: 2rem;
}

.white-card {
  border-radius: 8px;
  padding: 0.8rem 0.7rem;
  background-color: transparent;
}

.subheading {
  color: $black;
  font-size: 16px;
}

.card-heading {
  display: flex;
  height: 26px;

  flex-direction: row;

  gap: 1rem;
  align-items: center;
  font-size: 20px;
  margin: 0px !important;
  font-family: $fontFamilyRubik;
}
.card-heading img {
  margin-top: 1rem;
  height: 3rem;
  width: 3rem;
}

.card-subheading {
  font-family: $fontFamilyRubik;
  color: var(--text-grey);
  font-size: 16px;
  padding-left: 4rem;
  font-weight: 400;
}

/* -----------------------FAQ Section ----------------*/
.faq_section {
  margin-top: 5rem;
}

.faq_main_heading h3 {
  font-family: $fontFamilyRubik;
  font-size: 36px;
  font-weight: 600;
}

.faq_subheading h6 {
  font-family: $fontFamilyRubik;
  font-size: 14px;
  color: #212121;

  font-weight: 400;
}

.faq_headings {
  padding-top: 2rem;
  flex-direction: column;
  gap: 0.8rem;
}

.dropdown-icon {
  float: right;
  margin-left: 5px;
  transition: transform 0.4s ease;
  cursor: pointer;
}

.active .dropdown-icon {
  transform: rotate(180deg);
  cursor: pointer;
}

.accordion-title {
  font-weight: 500;
  font-family: $fontFamilyRubik;
  padding: 1rem;
  font-size: 24px;
}

.accordion-item {
  margin-bottom: 1rem;
  border-radius: 8px;
}

.accordion-title-text {
  margin-right: 10px;
}

.accordion_container {
  padding-top: 2.3rem;
  @include flex-center;
}

.accordion {
  font-family: $fontFamilyRubik;

  font-size: 18px;
  font-weight: 400;
}

.accordion-content-text {
  text-align: start !important;
  display: flex;
  justify-content: start;
  font-size: 20px;
  color: $text-grey;
  padding-top: 0px;
  padding-left: 1rem;
  padding-bottom: 3px;
}

/* Blogs Section */
.blogHeading {
  padding-bottom: 1.8rem;
}
.blogs_section {
  padding-top: 5rem !important;

  flex-direction: column;
}

.blogHeading {
  font-family: $fontFamilyRubik;
}

.card_container {
  @include flex-center;
  // justify-content:flex-start ;
  border-radius: 10px;
  width: auto;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 2rem;

  .card,
  .card img {
    border-radius: 10px 10px 0px 0px !important;
  }
  .card {
    cursor: pointer;
    border: transparent;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
}

.card {
  border-radius: 0px 0px 7px 7px !important;
}
.card-body {
  border-radius: 0px 0px 7px 7px !important;
}
.mobileSizeCarausel {
  height: 100%;
  width: 100%;
  padding-top: 2rem;
}
.desktopBlog .card img {
  width: 24rem;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
/*--------Brand Section----*/
.brandContainer {
  margin-top: 3rem;
  width: 100%;
  .ScheduleDemoBtn button {
    background-color: #e0efff;
    border-radius: 6rem;
    padding: 0.4rem 1.5rem 0.4rem 1.5rem;
    border: transparent;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: $fontFamilyRubik;
    box-shadow: 0px 6.39px 6.39px 0px #00000040;
    transition: all 0.1s ease;
  }

  .ScheduleDemoBtn button:hover {
    background-color: #007bff;
    color: #fff;
  }
}
/*---------Responsive---------*/
.responsive-text {
  font-size: clamp(1rem, 2vw, 2.5rem); /* Adjust these values as needed */
}

@media (min-width: 10px) and (max-width: 768px) {
  .brandContainer {
    margin-top: 1.5rem;
  }
  .faq_main_heading {
    @include flex-center;
    width: 100%;
  }
  .faq_main_heading h3 {
    font-size: 15px;
  }
  .smartEasyHead h3,
  .main-heading h3,
  .main-heading2 h3 {
    font-family: $fontFamilyRubik;
  }
  .sellection {
    @include flex-center;
    width: 100%;

    flex-direction: column;
  }
  .seconHero {
    margin-left: 45px;
  }
  .unlimitedSectionImgs {
    justify-content: center;
    gap: 6px;
  }
  .accordion-title {
    font-size: 15px;
  }
  .scheduleDemoBTN {
    @include flex-center;
    justify-content: start;
    padding-left: 10px;
  }
}

.smartEasyHead,
.main-heading {
  text-align: center !important;
}

@media screen and (max-width: 1450px) {
  .HeroContent p,
  .seconHero p,
  .SmartAndEasy h3,
  .main-heading2 h3 {
    font-size: 38px !important;
  }

  .HeroContent p,
  .seconHero p,
  .SmartAndEasy h3,
  .main-heading2 h3 {
    text-align: center !important;
  }

  .herobottmcont label {
    font-size: 22px !important;
  }

  .heroFotter label {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 1180px) {
  .HeroContent p,
  .seconHero p,
  .SmartAndEasy h3,
  .main-heading2 h3,
  .smartEasyHead,
  .main-heading h3 {
    font-size: 32px !important;
  }

  .unlimitedSectionImgs p,
  .whatyouget_subheading h6,
  .herobottmcont label,
  .faq_subheading h6,
  .accordion-title,
  .accordion-content-text {
    font-weight: 400 !important;
    font-size: 18px !important;
  }

  .accordion-title {
    font-weight: 500 !important;
  }

  .heroFotter label {
    font-size: 16px !important;
  }
}

@media screen and (max-width: 850px) {
  .HeroContent p,
  .seconHero p,
  .SmartAndEasy h3,
  .main-heading2 h3,
  .smartEasyHead,
  .main-heading h3 {
    font-size: 28px !important;
  }

  .unlimitedSectionImgs p,
  .herobottmcont label,
  .faq_subheading h6,
  .accordion-content-text,
  .accordion-title {
    font-size: 16px !important;
  }

  .heroFotter label {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 991px) {
  .paddingX991 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .hero {
    height: 70vh !important;
  }

  .HeroContent,
  .seconHero {
    padding-left: 0;
  }
}

@media (min-width: 10px) and (max-width: 1000px) {
  // .HeroContent p,
  // .herobottmcont label {
  //   font-size: clamp(1rem, 2vw + 1rem, 2rem);
  // }

  // .HeroContent p {
  //   font-size: 22px !important;
  //   padding-left: 10px;
  // }
  // .HeroContent {
  //   width: 95%;
  //   padding: 0px;
  // }
  // .scheduleDemoBTN {
  //   padding-top: 0px;
  //   padding-left: 10px;
  // }
  // .heroFotter {
  //   padding-bottom: 2rem;
  //   margin-bottom: 1rem;
  // }
  // .seconHero {
  //   height: 100%;
  // }

  .unlimitedSectionImgs {
    gap: 6px;
  }
  .sellection {
    padding-top: 0px;
  }
  .mobileSizeCarausel {
    height: 100%;
    width: 100%;

    img {
      width: 2.8rem;
      height: 3.2rem;
    }

    h6 {
      text-align: center;
      font-weight: 600;
      font-size: 18px;
      font-family: $fontFamilyRubik;
    }

    p {
      text-align: center;
      padding: 0px;
      margin: 0px;
      font-family: $fontFamilyRubik;
      width: 85%;
    }
    .cententCarausel {
      height: 100%;
      width: 100%;
      @include flex-center;
      flex-direction: column;
    }
  }
  .mobileBlogCarausel {
    background-color: transparent;
  }
  .mobileBlogCarausel .card {
    cursor: pointer;
    margin-right: 6px;
    border-radius: 10px;
    box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
      rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
  }
}
@media (min-width: 10px) and (max-width: 1200px) {
  .card_container {
    justify-content: center;
  }
  .rightArrow {
    position: absolute;
    right: 0rem;
    top: 4.5rem;
  }
  .LeftArrow {
    position: absolute;
    left: 0rem;
    top: 4.1rem;
    transform: rotate(180deg);
  }
  .rightArrow img,
  .LeftArrow img {
    height: 1.6rem !important;
    width: 1.8rem !important;
  }
  // .hero {
  //   height: 91vh;
  // }
}
@media (min-width: 10px) and (max-width: 770px) {
  .seconHero {
    display: none !important;
  }
  .smartEasyHead,
  .main-heading {
    justify-content: start;
    align-items: start !important;
  }
  .faq_subheading {
    text-align: justify;
    width: 100%;
    justify-content: start;
    align-items: start;
  }
  .blogHeading {
    padding-bottom: 1rem;
  }
  .SmartAndEasy {
    text-align: start;
  }
  .hero {
    height: 60vh;
    width: 100%;
  }
  .smartContainer,
  .about_section {
    padding-top: 1rem;
    height: 100%;
    width: 100%;
  }
  .firstContHero {
    height: 100% !important;
  }
}
@media (min-width: 10px) and (max-width: 557px) {
  .seconHero {
    display: none !important;
  }
  .smartEasyHead {
    padding-top: 3.1rem;
  }
  // .HeroContent p,
  // .herobottmcont label {
  //   font-size: clamp(1rem, 2vw + 1rem, 1.3rem);
  // }
  // .HeroContent label {
  //   font-size: clamp(0.5rem, 2vw + 0.5rem, 0.9rem) !important;
  // }
  .unlimitedSectionImgs {
    font-size: 12px;
  }

  .hero {
    height: 80vh !important;
  }
}

.HeroContent p {
  text-align: left !important;
}

@media (min-width: 10px) and (max-width: 400px) {
  .hero {
    height: 90vh !important;
  }
}

// @media (min-width: 800px) and (max-width: 991px) {
//   .smartcArds {
//     justify-content: start;
//   }
// }
.seconHero1 {
  position: absolute;
  bottom: 0;
  right: 0.2rem;
}

.seconHero1 img {
  width: 150px;
}
.seconHero img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hero {
  height: 91vh;
}
