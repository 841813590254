/* ---------------footer links  ------------------- */
.footer_Section {
  margin-top: 5rem;
  background: rgba(225, 240, 255, 1);
  position: sticky;
  font-family: "Rubik";
  font-size: 14px;
  /* bottom: 0; */
}

.footer-links {
  list-style-type: none;
  display: flex;
  width: fit-content;
  justify-content: center;
  align-items: center;
  /* align-items: ; */
  padding: 0;
  margin: 0;
  gap: 1rem;
}

.footer_column4_lis li {
  margin-bottom: 1rem;
}

.footer-links li {
  color: var(--text-grey);
}

.footer_column1 ul li a {
  text-decoration: none;
  color: var(--light-black-text);
}

.footer_column2 ul li a {
  text-decoration: none;
  color: var(--light-black-text);
}

.footer_column3 ul li a {
  text-decoration: none;
  color: var(--light-black-text);
}

.footer_column3 p {
  margin-bottom: 0;
}

.footer_email_label_container {
  display: flex;
  margin-top: 1rem;
  flex-direction: column;
}

.footer_email_label_container label {
  margin-bottom: 0;
  font-size: 14px;
}

.footer_email_label_container button {
  background: var(--0376F2, rgba(3, 118, 242, 1));
  width: fit-content;
  border: none;
}

.footer_email_input {
  border: 2px solid var(--0376F2, rgba(3, 118, 242, 1));
  width: fit-content;
  border-radius: 5px;
  padding: 0.1rem 0.1rem;
  width: 100%;
  height: 39px;
}

.footer_email_input input {
  border: none;
  outline: none;
}

.send_email {
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  padding: 0.2rem 0.8rem;
  height: 35px;
  position: absolute;
  right: 1px;
  top: 2px;
}

.footer_column1 ul li a:hover,
.footer_column2 ul li a:hover,
.footer_column3 ul li a:hover {
  text-decoration: underline;
}

.footer_column1 ul,
.footer_column1 h5 {
  text-align: left;
}

.footer_column2 ul,
.footer_column2 h5 {
  text-align: left;
}

.footer_column3 ul,
.footer_column3 h5 {
  text-align: left;
}

.footer_column1 ul li,
.footer_column2 ul li,
.footer_column3 ul li {
  margin-bottom: 0.5rem;
}

.footer_column4 {
  display: flex;
  flex-direction: column;
  text-align: left;
  justify-content: left;
}

.footer_column4 a {
  color: var(--light-black-text);
  text-decoration: none;
}

.footer_column4 a:hover {
  text-decoration: underline;
}

.footer_column4 img {
  width: 180px;
  float: left;
}

.footer_column4_text {
  margin-top: 1rem;
}

.footer_column4_socials img {
  padding-right: 1rem;
  width: 60px;
  background-color: transparent;
  margin-bottom: 0.5rem;
  transition: ease-in-out 0.1s;
}

.footer_column4_socials img:hover {
  transform: scale(1.1);
}

.footer_column4_socials {
  display: flex;
  justify-content: left;
}

.footer_column4_socials a {
  align-items: center;
}

.centerElement {
  display: flex;
  align-items: center;
  justify-content: center;
}

.footer-links li a {
  padding: 0;
}

.footer-links {
  margin-top: 0.5rem;
}

.footer-links li {
  cursor: pointer;
}

.footer_trisoft_copyright_section {
  background: rgba(225, 240, 255, 1);
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.footer_trisoft_copyright_section h6 {
  font-size: 14px;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.footer_trisoft_copyright_section hr {
  width: 100% !important;
}
