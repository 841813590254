/* Variables */
$grey: #ccc;
$black: #000;
$blue-hover: #0056b3;
$blue: #007bff;
$white: #fff;
$voilet: #8a2be2;
$text-grey: #999;

$light-blue: #e1f0ff;
$fontFamilyRubik: "Rubik", sans-serif;

/* Mixin for Flex Center */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}

.blogContents {
  padding: 0px;
  margin: 0px;
  background-size: contain;
  background-image: url(../../Assets//Images/blogHeroimg.png);

  justify-content: center;
  height: 95vh;
  display: flex;
  flex-direction: column;
}
.Blogs_heaading_wrapper h3 {
  font-family: $fontFamilyRubik;
  padding-left: 2rem;
  font-size: 2.6rem;
  color: $white;
}

.Blogs_heaading_description p {
  padding-left: 2rem;
  font-size: 1.1rem;
  color: $white;
  font-family: $fontFamilyRubik;
}

.Blogs_heaading_wrapper button {
  margin-left: auto;
  background-color: var(--button-gradient);
  border: none;
  border-radius: 6px;
  font-family: "Lato";
  font-weight: 600;
  font-size: 13px;
  color: var(--white);
  transition: ease-in-out 0.2s;
}

.Blogs_heaading_wrapper button img {
  margin-left: 0.2rem;
}

.Blogs_heaading_wrapper button:hover {
  transform: scale(1.1);
}

.Blogs_heaading_description {
  margin-top: 1rem;
}

.Blogs_heaading_description {
  color: var(--text-grey);
  font-family: "Roboto";
  font-weight: 600;
}

.blogs_leftimg_section img {
  width: 403px;
}

.blogs_leftimg_section {
  display: flex;
  justify-content: center;
  align-items: center;
}

.blogs_row {
  margin-top: 3rem;
  height: fit-content;
  align-items: center;
  display: flex;
  justify-content: space-around;
}

.blogs_right_section p {
  color: var(--text-grey);
  font-family: "Roboto";
  font-weight: 400;
}

.blogs_right_section {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.blogs_right_section h6 {
  font-family: "Roboto";
  font-weight: 600;
  text-align: left;
  display: flex;
  justify-content: left;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.pagination button {
  box-shadow: 0px 0px 2px 0px rgba(0, 0, 0, 0.25);
  padding: 8px 16px;
  margin: 0 10px;
  cursor: pointer;
  border: none;
  font-size: 14px;
  border-radius: 50%;
  aspect-ratio: 1;
}

.pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.blogs_section {
  padding: 20px;
}

.container {
  max-width: 1200px;
  margin: 0 auto;
}

.blogs_container {
  text-align: center;
}

.blogs_heading h3 {
  font-weight: 700;
  margin-bottom: 20px;
}

.card_container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.custom-card {
  box-shadow: 0px 0px 8.15px 0px rgba(0, 0, 0, 0.15);
  border-radius: 8px;
  border: none;
  padding: 16px;
  width: 300px;
}

.custom-card img {
  max-width: 100%;
  height: auto;
}

.pagination .active {
  font-weight: bold;
  background: var(--001AFF, rgba(0, 26, 255, 1));
  color: white;
}

.pagination .dots {
  padding: 8px 16px;
}
.blogHerocont {
  display: flex;
  height: 100%;
  width: 100%;
  align-content: start;
}
.imgGG1 img {
  height: 100%;
  width: 100%rem;
}
.imgBtM {
  display: flex;
  justify-content: space-between;

  font-family: $fontFamilyRubik;
  padding-left: 12px;
  padding-top: 2rem;
}

.imgBtM img {
  margin-right: 10px;
  height: 3rem;
  width: 3rem;
}
.blogUsEr {
  flex-direction: column;
  flex-wrap: wrap;
  display: flex;
}
.socialBlogs img {
  height: 2rem;
  width: 2rem;
}
@media (min-width: 10px) and (max-width: 700px) {
  .custom-card {
    margin-top: 10px;
    width: 90%;
  }
  .blogs_section {
    padding: 0px;
  }
  .blogs_heading {
    display: flex;
    width: 100%;
    padding-left: 12px;
  }
}
