.flexCenter {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flexColCenter {
    display: flex;
    flex-direction: column;
    justify-content: center;
}

.flexRowCenter {
    display: flex;
    flex-direction: row;

}

.descriptionText {
    color: var(--text-grey);
    font-size: 13px;
    font-family: 'Lato';
}