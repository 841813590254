/* Variables */
$grey: #ccc;
$black: #000;
$blue-hover: #0056b3;
$blue: #007bff;
$white: #fff;
$voilet: #8a2be2;
$text-grey: #999;

$light-blue: #e1f0ff;
$fontFamilyRubik: "Rubik", sans-serif;

/* Mixin for Flex Center */
@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  flex-wrap: wrap;
}
/* Blogs Section */
.BlogTitle h4 {
  font-weight: 700;
}


.blogHeading {
  font-family: $fontFamilyRubik;
}

.card_container {
  @include flex-center;
  // justify-content:flex-start ;
  border-radius: 10px;
  width: auto;
  gap: 2rem;
  flex-wrap: wrap;
  padding-top: 2rem;

  .card,
  .card img {
    border-radius: 10px;
  }
  .card {
    cursor: pointer;
    border: transparent;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px,
      rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;
  }
}

.mobileSizeCarausel {
  height: 100%;
  width: 100%;
  padding-top: 2rem;
}
.desktopBlog .card img {
  width: 24rem;
  height: 100%;
  box-shadow: rgba(0, 0, 0, 0.05) 0px 6px 24px 0px,
    rgba(0, 0, 0, 0.08) 0px 0px 0px 1px;
}
.BlogContentTT,
.BlogContent2 {
  padding-top: 1.8rem;
  display: flex;

  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: $light-blue;
  font-family: $fontFamilyRubik;
  padding-left: 4px;
  h3 {
    font-weight: 700;
  }
  p {
    font-size: 15px;
  }
}
.BlogContent2 {
  background-color: transparent;
}
.BlogContent {
  padding-top: 8px;
  padding-bottom: 1rem;
}
.bloGcenterImg {
  display: flex;
  height: 100%;
  justify-content: flex-end;
  width: 100%;

  align-items: center;
}

.bloGcenterImg img {
  margin-right: 1.2rem;
  height: 90%;
  width: 80%;
}
.BlogContent2 {
  margin-top: 1rem;
}

@media (min-width: 10px) and (max-width: 1000px) {
  .BlogContentTT {
    h3 {
      font-weight: 700;
    }
    p {
      font-size: 14px;
    }
  }

  .bloGcenterImg img {
    margin-right: 0px;

    height: 90% !important;
    width: 90% !important;
  }
  .bloGcenterImg {
    justify-content: center;
  }
}
