.privacy_policy_headingContainer h5 {
    font-family: "Rubik";
    font-weight: 400;
    font-size: 18px;
}

.privacyPolicy_points {
    font-family: "Rubik";
    color: var(--text-grey);
    font-size: 14px !important;
}

.privacyPolicy_points ul li {
    list-style-type: none;
    margin-bottom: 0.2rem;
}

.privacyPolicy_points ul {
    margin-bottom: 1rem;
    padding: 0.5rem 0.5rem;
}

@media (max-width: 991px) {
    .privacy_policy_headingContainer h5 {
        display: flex;
        text-align: center;
    }

    .privacyPolicy_points {
        display: flex;
        text-align: left;
        margin-left: auto;
        margin-right: auto;
    }
}

.inline-flex {
    display: inline-flex;

}