.howitworks_wrapper {
    margin-top: 0;
    margin-left: 0;
    margin-right: 0;
}

.headerSection_howitWorks {
    background-image: url("/src/Assets/Images/termsAndConditionImg.png");
    height: 50vh;
    margin-bottom: 1rem;
    justify-content: center;
    display: flex;
    justify-content: left;
    background-repeat: no-repeat;
    background-size: cover;
}

.center_text_wrapper h3 {
    font-family: "Rubik";
    color: var(--white);
    font-size: 48px;
}

.terms_imageContainer {
    display: flex;
    align-items: center;
    margin-left: 5rem;
}

.center_text_wrapper {
    width: fit-content;
}

.termsAndConditions_points ul li {
    list-style-type: none;
    margin-bottom: 1rem;
    font-family: "Rubik";
    font-size: 14px;
}

.termsAndConditions_points {
    margin-top: 2rem;
}