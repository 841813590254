// Define variables
$white: #ffffff;
$blue: #007bff;
$blue-hover: #0056b3;
$light-blue: #cce5ff;
$black: #000000;
$grey: #808080;
$font-lato: "Lato", sans-serif;
$font-rubik: "Rubik", sans-serif;

// Mixins
@mixin transition($property, $duration: 0.3s, $timing: ease-in-out) {
  transition: $property $duration $timing;
}

@mixin button-style($bg-color, $text-color, $hover-bg-color) {
  color: $text-color;
  background-color: $bg-color;
  @include transition(all);
  border: none;
  cursor: pointer;

  &:hover {
    color: $text-color;
    background-color: $hover-bg-color;
  }
}

// Import Google Fonts
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,300;1,400;1,700;1,900&family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");

// Navbar styles
.navbar {
  background-color: $white;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 3px 5px;
  z-index: 2;
  position: sticky;
  top: 0;

  &-nav {
    display: flex;
    justify-content: flex-end;
    flex-grow: 1;

    & .dropdown {
      display: flex;
      flex-direction: row;
    }
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }

  &-link {
    padding: 0.3rem 1rem !important;
    @include transition(color);

    &.freedemoBtn {
      @include button-style($blue, $white, $blue-hover);
    }
  }

  &-collapse {
    margin-left: 2rem;
  }

  &-toggle {
    display: flex;
    align-items: center;
    vertical-align: middle;

    & img {
      margin-right: 0.1rem;
    }
  }

  &-brand {
    img {
      width: 180px;
    }
  }
}

// Media queries
@media (min-width: 1200px) {
  .navbar-nav .dropdown {
    position: static;
    margin-left: auto;
  }
  .headerpoints {
    display: flex;
    gap: 1rem;
  }
}

.navbar-nav a {
  cursor: pointer;
}

@media (max-width: 1200px) and (min-width: 992px) {
  .navbar-nav .dropdown {
    position: static;
    margin-left: 1.5rem;
  }
}

.navbar-nav .nav-item {
  @include transition(border-radius);
  border-radius: 25px;

  &:hover {
    border-radius: 25px;
    background-color: $light-blue;
  }
}

.navbar-nav .nav-item.dropdown:hover {
  background-color: transparent !important;
}

.activeNav {
  border-radius: 25px;
  background-color: $light-blue;
}

.nav-item {
  display: flex;
  align-items: center;

  & a,
  & button {
    font-weight: 500;
    font-family: $font-rubik;
    font-size: 15px;
  }

  a {
    padding: 0.3rem 1rem !important;
  }
}

.logoImg img {
  height: 2rem !important;
  width: 7.4rem !important;
}

.buttons {
  width: 100%;
  display: flex;
  gap: 1rem;
  justify-content: center;

  & span {
    font-size: 12px;
    background: $white;
    color: $black;
    font-weight: 420;
    width: 100%;
    padding: 0 2rem;
    height: 100%;
    display: flex;
    align-items: center;
    border-top-right-radius: 4px;
    border-bottom-right-radius: 4px;
    justify-content: center;
  }

  & img {
    width: auto;
  }
}

.body-container {
  padding: 0 1.5rem;
}

.or-section {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;

  & hr {
    padding: 0 !important;
    border: 1px solid $grey;
    width: 100%;
  }

  & p {
    font-size: 13px;
    margin: 0;
    text-align: center !important;
  }
}

.full-name-section {
  padding-bottom: 0.5rem;
}
.Scheduledemo button {
  color: $white;
  border-radius: 15px;
  background-color: $blue;
}
.Scheduledemo button:hover {
  color: $white;
  border-radius: 15px;
  background-color: $blue-hover;
}

.Scheduledemo button:focus {
  box-shadow: none !important;
}
.hidden {
  display: none;
}

.paddingX {
  padding-left: 5.5rem;
  padding-right: 5.5rem;
}

@media screen and (max-width: 991px) {
  .paddingX {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .navbar-collapse {
    margin-left: 0;
  }
}
