:root {
  --white: #ffffff;
  /* --light-pink: #faf2ff; */
  --text-grey: #6b6f75;
  --grey: #666666;
  --text-green: #4bb06e;
  --light-grey: #b6b6b6;
  --black: #000000;
  --light-grey: #d3d3d3;
  --light-grey: #c4c4c4;
  --voilet: #b63eff;

  --blue: #0079facb;
  --blueHover: #0078fa;
  --voilet-blue: #e3e6ff;
  --light-blue: #dcdfff;
  --button-gradient: #6473fd;
  --primary-gradient: rgba(0, 26, 255, 0.11);
  --red-gredient: linear-gradient(180deg, #be0007 0%, #ed3737 100%);
  --green-gredient: linear-gradient(180deg, #009401 0%, #4eca4e 100%);
  --blue-gredient: linear-gradient(180deg, #0220a3 0%, #0b56c8 100%);
  --orange-gredient: linear-gradient(180deg, #f15000 0%, #fc7f40 100%);
  --teal-gredient: linear-gradient(180deg, #029290 0%, #33c9c7 100%);
  --yellow-gredient: linear-gradient(180deg, #e5b100 0%, #ffd540 100%);
  --pink-gredient: linear-gradient(180deg, #d20cae 0%, #e55ccd 100%);
  --purple-gredient: linear-gradient(180deg, #7e48c1 0%, #3b225b 100%);
  --black-gredient: linear-gradient(180deg, #2a2a2a 0%, #898989 100%);
  --light-black-text: #404040;
}

.App {
  background: rgba(255, 255, 255, 1);
  user-select: none;
}

.height80 {
  height: 80%;
}

/* Change scrollbar color */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  border-radius: 2px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #c4c4c4;
  border-radius: 2px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}

button {
  box-shadow: none;
  outline: none;
}

button:focus:not(:focus-visible) {
  outline: none;
}

button:focus {
  outline: none;
  box-shadow: none;
}
